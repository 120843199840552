
.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
  
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
  
    background-color: rgba(0, 0, 0, 0.7);
  }
  