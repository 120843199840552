.cities_list__wrapper {
  display: flex;
}

@media (max-width: 719px) {
  .cities_list__wrapper {
    flex-direction: column;
  }
}

.cities_list__favourite {
  display: flex;
  flex-direction: column;
}

@media(min-width: 720px) {
  .cities_list__favourite {
    width: 30%;
  }
}

@media(min-width: 992px) {
  .cities_list__favourite {
    width: 20%;
  }
}

.cities_list__favourite ul,
.cities_list__main ul {
  padding: 0;
  margin: 0;
}

.cities_list__favourite ul {
  width: 100%;
}

.cities_list__favourite li {
  list-style: none;

  font-weight: 600;
  font-size: 16px;
  line-height: 1.22em;

  letter-spacing: 0.03em;

  color: #000000;

  cursor: pointer;

  display: flex;
  align-items: center;
}

.cities_list__main li {
  display: flex;
  align-items: center;
}

.cities_list__main li:before,
.cities_list__favourite li:before {
  content: '';
  display: block;
  width: 21px;
  height: 21px;
  margin-right: 4px;
}

.cities_list__favourite_selected:before {
  background: url('../../assets/city-selected.svg') no-repeat 50% 50%;
}
.cities_list__favourite_selected {
  font-weight: 600 !important;
}

.cities_list__favourite ul > * + * {
  margin-top: 10px;
}

.cities_list__main {
  width: 100%;
  display: flex;

  color: #6b6b6b;
}
@media (max-width: 719px) {
  .cities_list__main {
    margin-left: 0;
    margin-top: 20px;
    margin-left: 25px;
  }
}

.cities_list__main ul {
  width: 25%;
}

.cities_list__main ul > li + li {
  margin-top: 10px;
}

.cities_list__main li {
  list-style: none;

  cursor: pointer;
  font-weight: 400;
  color: #6B6B6B;
  font-size: 16px;
  line-height: 1.2em;
}

@media(max-width: 719px) {
  .cities_list__main li {
    color: #000;
  }
}

.cities_list__main > ul + ul {
  margin-left: 15px;
}

.autocomplete__wrapper {
  margin-bottom: 30px;
  width: 100%;
  max-width: 420px;
  margin-bottom: 30px !important;
}

.autocomplete__wrapper input {
  padding-left: 25px;
  padding-right: 25px;
}

@media(max-width: 719px) {
  .autocomplete__wrapper input {
    padding-top: 13px;
    padding-bottom: 13px;
  }
}

.autocomplete__link {
  text-decoration: none;
  color: #000;
}

.city_current {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  margin-bottom: 30px;
  margin-left: 25px;
}

.city_current span {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #E30014;
  margin-bottom: 10px;
  margin-left: 15px;
}