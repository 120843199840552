.region_search__filter {
  display: flex;
  padding: 0;

  padding-bottom: 10px;

  border-bottom: 1px solid #ecf0f1;
}

@media (max-width: 719px) {
  .region_search__filter {
    display: none;
  }
}

.region_search__filter li {
  list-style: none;
  cursor: pointer;
}

.focus {
  color: red;
}

.region_search__filter > * + * {
  margin-left: 10px;
}
