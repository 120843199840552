.container {
  position: fixed;
  left: 50%;
  top: 15%;
  transform: translate(-50%);
  -webkit-transform: translate(-50%);

  max-width: 1600px;
  width: 97%;

  background-color: white;

  z-index: 1000;
}

@media (max-width: 719px) {
  .container {
    width: 100%;

    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    transform: translate(0);
    -webkit-transform: translate(0);
  }
}

.region_search__wrapper {
  border: 1px solid #bababa;

  padding: 40px 50px;
}

@media (max-width: 719px) {
  .region_search__wrapper {
    min-height: 100vh;
    padding: 10px 25px;
    border: none;
  }
}

.region_search__close {
  position: absolute;
  top: 57px;
  right: 55px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

@media(max-width: 719px) {
  .region_search__close {
    top: 25px;
    left: 25px;
    right: auto;
    width: 32px;
    height: 32px;
    padding: 4px;
    box-sizing: border-box;
  }
}

.region_search__close img {
  display: block;
  width: 24px;
  height: 24px;
}

fieldset {
  border-color: #CED4DA;
}
