.button {
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;
}

.button > * + * {
  margin-left: 10px;
}

@media (max-width: 1099px) {
  .button span {
    display: none;
  }
}
